import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Link } from 'gatsby';
import { List, ListItem, ListItemText, ListSubheader, makeStyles, Paper } from '@material-ui/core';
import Layout from '../components/layout';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const Page = () => {
  const classes = useStyles();

  return (
    <Layout noheader>
      <Typography variant="h2" component="h1" gutterBottom>
        Berrac
      </Typography>

      <Paper className={classes.paper}>
        <List subheader={<ListSubheader component="div">Église</ListSubheader>}>
          <ListItem
            button
            component={Link}
            href="https://photos.app.goo.gl/PjXz3dUu8iJ2ZgEbA"
          >
            <ListItemText primary="Cloche nord" />
          </ListItem>
          <ListItem
            button
            component={Link}
            href="https://photos.app.goo.gl/1d3BFPG9Aaeympi7A"
          >
            <ListItemText primary="Cloche sud" />
          </ListItem>
          <ListItem
            button
            component={Link}
            href="https://photos.app.goo.gl/2CrgPxfJkb925mXU7"
          >
            <ListItemText primary="Intérieur" />
          </ListItem>
        </List>
      </Paper>

      <Paper className={classes.paper}>
        <List>
          <ListItem
            button
            component={Link}
            href="https://photos.app.goo.gl/pHmUT4P12AU8z3JT6"
          >
            <ListItemText primary="Chemin du Turon" />
          </ListItem>
        </List>
      </Paper>
    </Layout>
  );
};

export default Page;
